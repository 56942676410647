<template>
  <div class="join">
    <div class="banner" :style="{ backgroundImage: `url(${bannerUrl})` }">
      <div class="banner-box">
        <div class="banner-title">新闻中心</div>
        <div class="title-eng">News&nbsp;Center</div>
        <div class="banner-msg">
          快捷浏览，掌握灵鹿一手资讯。
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div class="news-box" v-if="showId==1">
          <section data-role="outer" class="article135" style="padding: 10px;">
            <section data-role="paragraph" class="_135editor">
              <p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 30px; font-family: 微软雅黑; color: #ffffff;"><strong>灵鹿获海伦堡控股近千万战略投资</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="letter-spacing: 0px; font-style: normal; font-size: 16px; font-family: 微软雅黑; color: #808080">近日,灵鹿已与海伦堡控股达成战略合作协议,并成功融资近千万元。灵鹿智能引入中国房地产50强企业海伦堡控股作为战略投资人,海伦堡控股业务版图覆盖珠江三角洲地区、长江三角洲地区、京津冀地区、华西地区及华中地区15个省市45个城市,业态类型涵盖住宅、商业和创意科技园。</span>
              </p>
             <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/2.png' alt="" width="80%;">
              </p>

              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">未来,海伦堡控股将与灵鹿智能在上述全国项目领域展开全方位业务合作,不断深化住宅、商业、科技园建筑物联网发展。作为智能家居先行者,灵鹿智能即将在诸多项目实践中走上高速发展阶段。</span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">灵鹿智能作为国内知名的智能家居品牌,拥有完善且稳定的软硬件产品生态、行业内最具竞争力的全屋智能解决方案。灵鹿智能将依托自身强大的研发与设计能力、完善的产品矩阵、良好的售前售后服务,为海伦堡控股的地产项目提供涵盖智慧安防、智慧用电、智慧灯光、智慧门窗、影音娱乐等多场景在内的全屋智能解决方案,为业主带来更高效、便捷、灵活的智能生活新体验。</span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">目前,灵鹿智能已与中海、绿城、万科等10余家国内房产巨头合作应用于200多个高端楼盘,也与上百个家电企业实现平台对接。未来灵鹿智能还将继续赋能合作伙伴,打造更美好的数字化生活空间,构建高品质的智慧人居生活。</span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">除此之外,灵鹿智能还引入自然人股东范志敏先生,他作为长期深耕资本市场的专业人士,曾主导完成浙江多家企业IPO上市及整体资本运作。他表示,灵鹿智能作为建筑物联网、智能家居领域的细分行业龙头,在技术、产品、营销等方面拥有较强的综合竞争优势,必将在产业高速发展浪潮中占有一席之地。与原来的智轩科技相比,灵鹿智能的股权架构设计更加合理,公司治理更加完善。与广州市开发区、海伦堡的战略合作,也有利于形成“华东+华南”、“有线+无线”、“零售+工程”更全面的战略布局。</span>
              </p>
              <p>
                <br />
              </p>
            </section>
          </section>
        </div>
        <div class="news-box" v-if="showId==2">
        <section data-role="outer" class="article135" style="padding: 10px;">
            <section data-role="paragraph" class="_135editor">
              <p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 30px; font-family: 微软雅黑; color: #ffffff;"><strong>灵鹿全屋智能正式发布，邀您共赴理想生活</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="letter-spacing: 0px; font-style: normal; font-size: 16px; font-family: 微软雅黑; color: #808080;">2022年9月7日，灵鹿智能以“越过山丘•遇见灵鹿”为主题，于浙江宁波举办了灵鹿品牌发布会。</span>
              </p>
              <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling1.png' alt="" width="80%">
              </p>

              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">除了有来自全国各地的智能家居行业的小伙伴外，华为云、海伦堡控股、信科集团、中航建、中天美好生活服务集团、约克（中国）、莱福德科技、向往智能、凯越集团、看见未来家、卡巴尔电气、亚翔电子等灵鹿挚友也受邀前来参加了本次灵鹿品牌发布会。</span>
              </p>
<p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 20px; font-family: 微软雅黑; color: #ffffff;"><strong>灵鹿全屋智能，邀您一起共赴理想生活</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">“家是一个注定要陪伴我们三分之二生命旅程的空间，但很多时候它还仅仅只停留在简单的居住空间上，所以我相信改善人与空间的关系，就一定能为人类创造幸福”灵鹿联合创始人&CPO李林在灵鹿品牌发布中表示。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling2.png' alt="" width="80%">
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">李白的《访戴天山道士不遇》里写到“树深时见鹿，溪午不闻钟”，意为林深幽静，有鹿出没，正值午间时分却听不见钟声。诗中描述的场景宛如世外桃源，鹿和自然和谐相处.而这和我们所追求的目标不谋而合，希望走进每一个智慧家庭，就像走进了大自然，人与空间之间是美好又和谐的关系。</span>
              </p>
              <p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 20px; font-family: 微软雅黑; color: #ffffff;"><strong>理想生活，超前一点 温情 | 简奢 | 极智</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">在介绍完灵鹿的品牌故事后，灵鹿CPO李林直奔主题，为灵鹿的新品作首次发布。他用专业且生动的语言为台下的观众传达了灵鹿温情、简奢、极智的品牌特质。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling3.png' alt="" width="80%">
  </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling4.png' alt="" width="80%">
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">此次发布会上共发布了8个系列共计20款产品以及灵鹿全屋智能系统，从室外安防系统到室内的暖通、照明、遮阳等智能系统，再到别墅的智能影音系统等，一应俱全，为用户提供一站式全屋智能解决方案。我们希望灵鹿的出现，必将给平凡的生活增添一抹不一样的色彩。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling5.png' alt="" width="80%">
 </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling6.png' alt="" width="80%">
 </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling7.png' alt="" width="80%">
              </p>
              <p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 20px; font-family: 微软雅黑; color: #ffffff;"><strong>灵鹿全屋智能为千家万户创造有温度的智能空间</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">灵鹿创始人&CEO曾鹏发布了公司发展战略，提到了要做令人信赖、充满温度的智能家居品牌，坚持品牌为先、产品为本、服务为魂，并在一年内通过星火计划，让灵鹿产品进入千城千店，覆盖100%地级市，用户满意度95%，达到业界最高水准，成为行业标杆。</span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling8.png' alt="" width="80%">
              </p>
  <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">他表示，灵鹿将始终践行“用户第一，伙伴先行”的价值观，朝着“为千家万户创造有温度的智能空间”的目标迈进,为用户提供真正的“有+无”智能系统解决方案以及真正平衡性价比和稳定性的产品。</span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling9.png' alt="" width="80%">
 </p>
<p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080">战略发布后，灵鹿与华为云计算、信科集团、约克（中国）、中航建、向往智能、深圳莱福德等公司代表现场签订了战略合作协议。</span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling10.png' alt="" width="80%">
              </p>
<p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">不仅如此，到场的鹿友们都对本次发布的全系列产品赞不绝口，有数十家公司当场签约成为灵鹿首批经销商。聚沙成塔，集腋成裘，我们始终坚持用户第一、伙伴先行，灵鹿将在全国经销合作伙伴的呵护下，茁壮成长，改写行业神话！</span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling11.png' alt=""  width="80%">
</p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling12.png' alt="" width="80%">
</p>

<p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 20px; font-family: 微软雅黑; color: #ffffff;"><strong>首届灵鹿日，与你面对面</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
<p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">与此同时，9月7日不仅是灵鹿品牌发布日，也是我们的灵鹿日。公司倡导大家放下工作，互相交流。因此在今年的灵鹿日，我们开展了“伙伴面对面”交谈会以及各色团队活动。在别开生面的团队秀环节，灵鹿伙伴们并肩作战，所向披靡，加深了双方的默契，也让我们更加期待明年的9月7日～</span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling13.png' alt=""  width="80%">
</p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling14.png' alt="" width="80%">
</p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling15.png' alt="" width="80%">
              </p>
<p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">我们早在创立时就希望，灵鹿能成为一个年轻、充满活力的品牌。就像我们的团队一样，血管里流淌着梦想的颜色。这些可爱的小伙伴们始终践行着灵鹿坚持奋斗、倾心热爱、创新极质的价值观，渴望成功也能共渡难关。就是这样的一群人，坚信越过山丘，便能遇见彩虹。</span>
              </p>
<p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;"><p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:22px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">我们越过山丘，见过彩虹与荆棘。但始终底色，不变感谢各位鹿友们的见证。至此,灵鹿来了！</span>
              </p></span>
              </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling16（feng）.png' alt="" width="80%">
    </p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/ling17.png' alt="" width="80%">
              </p>
              <p>
                <br />
              </p>
            </section>
          </section>
        </div>
        <div class="news-box" v-if="showId==3">
 <section data-role="outer" class="article135" style="padding: 10px;">
            <section data-role="paragraph" class="_135editor">
              <p style="text-align:center;margin-bottom:16px;text-autospace:ideograph-numeric;" align="center">
                <span style=";color:#ffffff"><strong><span
                      style="font-size: 30px; font-family: 微软雅黑; color: #ffffff;"><strong>获多家媒体报道，华为云助力灵鹿打造行业领先的智能家居数字化解决方案</strong></span></strong><strong><span
                      style="font-size: 21px; font-family: 微软雅黑; color: #ffffff;"></span></strong></span>
              </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="letter-spacing: 0px; font-style: normal; font-size: 16px; font-family: 微软雅黑; color: #808080;">近日，灵鹿全屋智能获宁波日报、甬派新闻、钱江晚报、浙里有为等多家知名媒体报道，华为云宁波沃土工场助力浙江灵鹿智能科技有限公司，打造了全国领先的智能家居数字化解决方案。</span>
              </p>
              <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua1.png' alt="">
<img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua2.png' alt="">
              </p>

              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">宁波沃土工场是宁波高新区与华为云联合打造的技术创新高地，过去三年取得了瞩目的成绩。2022年又是浙江数字经济“一号工程”升级版的开局之年，数字经济新五年征程中，宁波高新区始终走在前列。宁波高新技术产业开发区管理委员会副主任俞承豪表示，宁波高新区充分发挥科创策源功能定位，全力推进“数字经济一号工程”。市区两级均出台有专项政策，不仅给予数字化改革的企业方资金支持，也给予技术创新平台支持，灵鹿智能便是其中的受益企业代表之一。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua3.png' alt="" width="80%">
   </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">灵鹿智能作为浙江在智能家居领域的代表性科技企业，自主研发并发布了多个系列近百种智能家居产品，打造了业内领先的全屋智能系统。在了解到沃土工场的技术能力后，灵鹿就在自己的产品研发过程中，与华为技术团队展开合作。华为团队针对智能家居实际应用场景、平台云原生部署等投入技术支撑，灵鹿全屋智能系统实现了本地化，使得部署更稳定、云端负载更灵活，保持行业内领先水平，更好地践行品牌为先、产品为本、服务为魂的企业战略，为越来越多的用户提供稳定且开放的一站式智能解决方案。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua4（feng）.png' alt="" width="80%">
   </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">这并不是灵鹿与华为的第一次遇见与合作，此前灵鹿团队还曾获得华为开发者大赛IOT赛道全球总冠军。灵鹿以1+2+N（1个物联网平台、端+云双驱动、N个智能硬件）的产品架构模式，同时具备私人定制、贴心守护、轻简随心、内外兼修、主动智慧、智久弥新等品牌优势，专注于打造悦己、悦生活的理想家。</span>
              </p>
 <p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua5.png' alt="" width="80%">
</p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua6.png' alt="" width="80%">
</p>
<p style="text-align:center;margin-top:30px;margin-bottom:30px;">
                <img src='https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/hua7.png' alt="" width="80%">
   </p>
              <p style="text-align:justify;margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;text-indent:34px;padding:0 0 0 0 ;text-autospace:ideograph-numeric;text-justify:inter-ideograph;line-height:26px;"
                align="justify">
                <span
                  style="font-family: 微软雅黑; letter-spacing: 0px; font-style: normal; font-size: 16px; color: #808080;">对于灵鹿而言，打造极具温情、简奢、极智的全屋智能家居产品并不是最终目标。我们更希望打造一个真正有温度的领先世界的中国智能家居品牌，更加稳定、更加开放，做好主动服务，让灵鹿走进千家万户，用户满意度达到行业第一，为更多家庭提供有温度的理想生活。</span>
              </p>
              <p>
                <br />
              </p>
            </section>
          </section>
</div>
      </div>
    </div>

  </div>
</template>

<script>
import imgsUrl from '../utils/imgAddress';


export default {
  data() {
    return {
      showId:0,
      bannerUrl: imgsUrl.bannerMd.newsBanner,
    }
  },
  
  mounted() {
    this.showId = this.$route.query.id
  }
}
</script>
<style scoped lang="scss">
.join {
  .banner {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    position: relative;
    letter-spacing: 5px;

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #d8d8d8;

      .banner-title {
        width: 220px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        text-align: left;
      }

      .banner-msg {
        letter-spacing: 2px;
        color: #999999;
        font-size: 22px;
      }
    }
  }

  .main {
    height: auto;
    margin: 120px 0;
    display: flex;
    justify-content: center;


    .main-box {
      width: 1400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .news-box {
        width: 80%;
      }



    }
  }


}
</style>
